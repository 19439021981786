import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getBoards(params) {
  return request({
    url: ENDPOINT.BOARDS,
    method: 'get',
    params
  })
}

export function createBoard(data) {
  return request({
    url: ENDPOINT.BOARDS,
    method: 'post',
    data
  })
}

export function deleteBoard(id) {
  return request({
    url: `${ENDPOINT.BOARDS}?id=${id}`,
    method: 'delete'
  })
}

export function updateBoard(data) {
  return request({
    url: ENDPOINT.BOARDS,
    method: 'patch',
    data
  })
}

export function getBoardItems(params) {
  return request({
    url: ENDPOINT.BOARD_ITEMS,
    method: 'get',
    params
  })
}

export function createBoardItem(data) {
  return request({
    url: ENDPOINT.BOARD_ITEMS,
    method: 'post',
    data
  })
}

export function deleteBoardItem(id) {
  return request({
    url: `${ENDPOINT.BOARD_ITEMS}?id=${id}`,
    method: 'delete'
  })
}
