import { render, staticRenderFns } from "./AddItems.vue?vue&type=template&id=56be4687&scoped=true&"
import script from "./AddItems.vue?vue&type=script&lang=js&"
export * from "./AddItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56be4687",
  null
  
)

export default component.exports