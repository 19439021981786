<template>
  <div class="shared-detail">
    <el-row
      class="title"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <h3 class="m-0">
        THÔNG TIN CHI TIẾT: {{ detail.name ? ` ${detail.name.toUpperCase()}` : '' }}
      </h3>
    </el-row>
    <div class="tag-tab shared-index">
      <el-row class="table-title" type="flex" align="center">
        <div class="table-title__left">
          <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
            <el-tab-pane name="boards">
              <div slot="label">
                Danh sách yêu thích
                <span>({{ total }})</span>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div>
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="small"
            @click="addBoardItems"
          >Thêm</el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            size="small"
            @click="handleChangeTab"
          >Làm mới</el-button>
        </div>
      </el-row>
      <div v-if="detail.id">
        <list-board-items
          :loading="callingAPI"
          :data="allBoardItems"
          @reload="handleReloadAfterDelete"
        ></list-board-items>
        <el-row type="flex" justify="end" class="my-4">
          <el-pagination
            :current-page="page"
            :page-size="limit"
            :total="total"
            background
            layout="total, prev, pager, next"
            @current-change="handleChangePage"
          ></el-pagination>
        </el-row>
        <add-board-items-popup
          v-if="addVisible"
          :board="detail"
          :visible="addVisible"
          @close="addVisible = false"
          @reload="loadData"
        ></add-board-items-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBoards, getBoardItems } from '@/services/board'

import { getTranslation } from '@/utils/filters'

import ListBoardItems from './components/ItemList.vue'
import AddBoardItemsPopup from './components/popup/AddItems.vue'

export default {
  name: 'Tags',
  components: {
    ListBoardItems,
    AddBoardItemsPopup
  },
  data() {
    return {
      addVisible: false,
      callingAPI: false,
      allBoardItems: [],
      activeTab: 'tags',
      total: 0,
      limit: 10,
      page: 1,
      searchText: '',
      detail: {}
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        page: this.page,
        per_page: this.limit,
        locale: this.language,
        board_id: this.$route.params.id
      }
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    getBoardDetail() {
      this.callingAPI = true
      getBoards({
        locale: this.language,
        id: this.$route.params.id
      }).then((response) => {
        this.detail = response.result.map((r) => ({
          ...r,
          name: getTranslation(r, this.language) ? getTranslation(r, this.language).name : '',
          description: getTranslation(r, this.language) ? getTranslation(r, this.language).description : ''
        }))[0]
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },
    loadData() {
      this.callingAPI = true
      this.addVisible = false
      this.getBoardDetail()
      getBoardItems(this.params).then((response) => {
        this.allBoardItems = response.result.map((i) => ({
          ...i,
          place: {
            ...i.place,
            ...i.place.translation
          }
        }))
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    },
    addBoardItems() {
      this.addVisible = true
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    handleReloadAfterDelete() {
      if (this.allBoardItems.length === 1 && this.page > 1) {
        this.page = this.page - 1
      }
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
