<template>
  <el-dialog
    top="80px"
    width="500px"
    title="Thêm item vào danh sách"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Loại điểm đến" prop="placeType">
        <el-select
          v-model="form.placeType"
          :disabled="callingAPI"
          filterable
          class="w-100"
          @change="handleChangePlaceType"
        >
          <el-option v-for="p in placeTypes" :key="p.value" :label="p.name" :value="p.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Điểm đến" prop="placeIds">
        <el-select
          v-model="form.placeIds"
          :disabled="callingAPI"
          multiple
          filterable
          remote
          class="w-100"
          placeholder="Nhập tên để tìm kiếm điểm đế"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleAddItems">
        Thêm
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createBoardItem } from '@/services/board'
import { listPlaces } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'AddBoardItems',
  props: {
    board: Object,
    visible: Boolean
  },
  data() {
    return {
      callingAPI: false,
      searching: false,
      searchText: '',
      places: [],
      form: {
        placeType: '',
        placeIds: []
      },
      rules: {
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    dataInputs() {
      const data = this.form.placeIds.map((id) => ({
        board_id: this.board.id,
        place_id: id
      }))
      return data
    },

    params() {
      return {
        locale: this.language,
        place_types: this.form.placeType,
        per_page: 5,
        search_text: this.searchText
      }
    },

    placeTypes() {
      return getPlaceTypes().filter((p) => p.type === 'place')
    }
  },
  methods: {
    handleChangePlaceType() {
      this.form.placeIds = []
      this.searchText = ''
      this.getPlaces()
    },
    getPlaces() {
      this.searching = true
      listPlaces(this.params).then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },
    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },
    handleAddItems() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          Promise.all(this.dataInputs.map((input) => {
            try {
              return createBoardItem(input)
            } catch (error) {
              //
            }
          })).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Thêm thành công'
            })
            this.$emit('reload')
            this.callingAPI = false
          }).catch(() => {
            this.callingAPI = false
          })
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
