<template>
  <el-table v-loading="loading" :data="data">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="" prop="avatar" :width="100">
      <template slot-scope="scope">
        <div class="table-column">
          <el-row type="flex" align="middle">
            <el-col :lg="8" title="Private">
              <i
                v-if="scope.row.place.status && scope.row.place.status === 'private'"
                class="fas fa-lock"
              ></i>
              <i v-else-if="!scope.row.place.status && !scope.row.place.public" class="fas fa-lock"></i>
            </el-col>
            <el-col :lg="16" class="avatar-img">
              <img
                :src="
                  scope.row.place.avatar && scope.row.place.avatar.url ? scope.row.place.avatar.url : imageDefaultUrl
                "
                alt="href"
              />
              <i v-if="scope.row.place.vrtour" class="fas fa-play-circle" title="Tour VR"></i>
              <i v-if="scope.row.place.audio" class="fas fa-volume-up" title="Audio"></i>
            </el-col>
          </el-row>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Tên" prop="name">
      <template slot-scope="scope">
        {{ scope.row.place.name }}
      </template>
    </el-table-column>
    <el-table-column label="Ngày khởi tạo" prop="created_at" :width="150">
      <template slot-scope="scope">
        <span>{{ scope.row.created_at }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngôn ngữ" width="250" align="center">
      <template slot-scope="scope">
        <el-row type="flex" align="middle" justify="center">
          <img
            v-for="locale in locales"
            :key="locale.code"
            class="language"
            :class="{ inactive: !scope.row.place.translations || !scope.row.place.translations.find((t) => t.locale === locale.code)}"
            :src="require(`@/assets/images/${locale.code}.png`)"
            alt="href"
          />
        </el-row>
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="150" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Xóa" placement="top">
          <el-button icon="el-icon-delete" type="danger" circle size="small" @click="handleDelete(scope.row)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteBoardItem } from '@/services/board'

import { getToShowRouter } from '@/utils/filters'

export default {
  name: 'ListBoardItems',
  props: {
    data: Array,
    loading: Boolean
  },
  data() {
    return {
      imageDefaultUrl: '/default-image.jpeg'
    }
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    getToShowRouter,
    handleDelete(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(() => {
        deleteBoardItem(row.id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: `Xóa thành công`
          })
          this.$emit('reload')
        })
      })
    },

    handleUpdate(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin: 5px 0;
  &.language {
    width: 28px;
    height: 28px;
    border-radius: 0px;
    margin: 0 5px;
    &.inactive {
      filter: grayscale(100%);
    }
  }
}
.table-column {
  .fa-lock {
    color: #ff5252;
  }
  .avatar-img {
    position: relative;
    svg {
      position: absolute;
    }
    .fa-volume-up {
      right: -2px;
      bottom: 5px;
      color: #409eff;
      font-size: 18px;
    }
    .fa-play-circle {
      right: -2px;
      top: 0px;
      font-size: 20px;
      color: #67c23a;
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin: 5px 0;
    &.language {
      width: 28px;
      height: 28px;
      border-radius: 0px;
      margin: 0 5px;
      &.inactive {
        filter: grayscale(100%);
      }
    }
  }
  a {
    color: #409eff;
  }
  .form-edit {
    display: flex;
    align-items: center;
  }
}
</style>
